import React from "react";
import { Route, Redirect } from "react-router-dom";

import { Login, ForgotPassword } from "~/pages/generic";

export const Routes = () => {

  return (
    <>
      <Route path="/login" exact component={Login} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/">
        <Redirect to="/login" />
      </Route>
    </>
  )
}
