import styled from '@emotion/styled';
import { keyframes } from "@emotion/react";
import { mediaQuery } from "~/styles";

const placeholder = keyframes`
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
`;

export const Container = styled.aside`
  background-color: #FFF;
  padding: 12px 20px;
  border-right: 1px solid #E4E4E4;
  height: 100vh;
  overflow: hidden;
  transition: 0.2s;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 100%;
  transform: translateX(100%);

  ${mediaQuery('md')} {
    width: 255px;
    min-width: 255px;
    position: absolute;
    top: 0;
    right: initial;
    left: 0;
    transform: initial;
  }

  ${mediaQuery('lg')} {
    position: relative;
  }
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 30px 1rem 0;
  
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
    padding: 50px 1rem 0;
  }
`;

export const Logo = styled.img`
  position: relative;
  animation: ${placeholder} 1.5s infinite;
`;

export const Navbar = styled.nav``;

export const Menu = styled.ul``;

export const MenuItem = styled.li`
  height: 56px;
  padding: 16px;
  display: flex;
  gap: 16px;
  border-radius: 12px;
  align-items: center;
  justify-content: flex-start;
  background-color: #FFFFFF;
  
  &:first-of-type {
    background-color: #F7F7F7;
  
    & span {
      background-color: #FFFFFF;
    }
  }
  
  & span {
    border-radius: 8px;
    background-color: #F7F7F7;
    animation: ${placeholder} 1.5s infinite;
    
    &:first-of-type {
      height: 24px;
      width: 24px;
    }

    &:last-child {
      width: 115px;
      height: 18px;
    }
  }
`;

