import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: []
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    associateBusiness(state, action) {
      state.list = action.payload;
    },
    resetBusiness(state) {
      state.list = [];
    }
  },
});

export const { associateBusiness, resetBusiness } = businessSlice.actions;
export default businessSlice.reducer;