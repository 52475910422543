import React, { useState } from "react"; 
import './index.css';
import avatarImg from '../../assets/avata_min.png'

import { useSelector } from "react-redux";

const ConfigModal = ({
  modalIsOpen,
  onClose,
}) => {

  const { user } = useSelector(state => state)

  const [radios, setRadios] = useState({
    first: true,
    second: true
  })

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('PT')
  }

  return(
    <div className={`popup_wrapper ${modalIsOpen ? null : 'mfp-hide'}`} >
      <div className="close-popup" onClick={() => onClose()} ></div>
      <div className="popup popup_settings" id="popup-settings">
        <div className="popup__title h6">Configurações</div>
        <div className="popup__tabs js-tabs">
          <div className="popup__nav">
            <a className="popup__link js-tabs-link active" href="/#">Perfil</a>
            {/* <a className="popup__link js-tabs-link" href="/#">Nova Empresa</a> */}
          </div>

          <div className="popup__container">
            <div className="popup__item js-tabs-item" style={{ display: 'block' }}>
              <div className="popup__user">
                <div className="popup__label popup__label_mb32">Sua foto</div>
                <div className="popup__line">
                  <div className="popup__ava"><img className="popup__pic" src={avatarImg} alt="" /></div>

                  {/* <div className="popup__details">
                    <div className="popup__btns">
                      <div className="popup__loading">
                        <input className="popup__file" type="file" />
                        <button className="popup__upload">Atualizar foto</button>
                      </div>
                      <button className="popup__btn btn btn_gray">Deletar</button>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="popup__fieldset">
                <div className="popup__row">
                  <div className="popup__field">
                    <div className="popup__label">Seu Nome Completo</div>
                    <div className="popup__wrap">
                      <input
                        value={user.name}
                        className="popup__input"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="popup__field">
                    <div className="popup__label">Username</div>
                    <div className="popup__wrap">
                      <input
                        value={user.username}
                        className="popup__input"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="popup__row">
                  <div className="popup__field">
                    <div className="popup__label">Email</div>
                    <div className="popup__wrap">
                      <input
                        value={user.email}
                        className="popup__input"
                        type="email"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="popup__field">
                    <div className="popup__label">Data de nascimento</div>
                    <div className="popup__wrap">
                      <input
                        value={user ? formatDate(user.birth_date) : ''}
                        className="popup__input"
                        type="text"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="popup__label">Notificações</div>

              <div className="popup__variants">
                <label className="checkbox">
                  <input className="checkbox__input" type="checkbox" checked={radios.first} onChange={() => setRadios({...radios, first: !radios.first})} />
                  <span className="checkbox__in">
                    <span className="checkbox__tick"></span>
                    <span className="checkbox__text">Previsão de hospitalizações</span>
                  </span>
                </label>
                <label className="checkbox">
                  <input className="checkbox__input" type="checkbox" checked={radios.second} onChange={() => setRadios({...radios, second: !radios.second})}/>
                  <span className="checkbox__in">
                    <span className="checkbox__tick"></span>
                    <span className="checkbox__text">Previsão de procedimentos</span>
                  </span>
                </label>
                <label className="checkbox">
                  <input className="checkbox__input" type="checkbox" />
                  <span className="checkbox__in">
                    <span className="checkbox__tick"></span>
                    <span className="checkbox__text">Previsão de doenças</span>
                  </span>
                </label>
              </div>

              <button className="popup__btn btn btn_blue" type="submit" onClick={() => onClose()}>Salvar Perfil</button>
            </div>

            {/* <div className="popup__item js-tabs-item">
              <form className="popup__form">
                <div className="popup__title h6">Alterar Senha</div>
                <div className="popup__field field js-field">
                  <div className="field__label">Senha atual</div>
                  <div className="field__wrap"><input className="field__input js-field-input" type="password" /></div>
                </div>
                <div className="popup__field field js-field">
                  <div className="field__label">Nova senha</div>
                  <div className="field__wrap"><input className="field__input js-field-input" type="password" /></div>
                </div>
                <div className="popup__field field js-field">
                  <div className="field__label">Confirme a nova senha</div>
                  <div className="field__wrap"><input className="field__input js-field-input" type="password" /></div>
                </div><button className="popup__btn btn btn_blue btn_wide">Alterar Senha</button>
              </form>
            </div>

            <div className="popup__item js-tabs-item">
              <div className="popup__box">
                <div className="popup__title h6">QR Code</div>
                <div className="popup__scan">
                  <div className="popup__preview"><img src="img/qr-code.png" alt="" /></div>

                  <div className="popup__details">
                    <div className="popup__text">Se você quiser ligar a autenticação de 2 fatores, use <a href="#">Google Authenticator app</a> para ler o QR Code e, em seguida, digite o código de seis dígitos fornecido pelo aplicativo nos campos abaixo.</div><button className="popup__btn btn btn_border">Download 2FA App</button>
                  </div>
                </div>
              </div>

              <div className="popup__box">
                <div className="popup__title h6">Código de Seis Dígitos</div>
                <div className="popup__numbers">
                  <div className="popup__number success"><input type="tel" value="5" /></div>
                  <div className="popup__number success"><input type="tel" value="6" /></div>
                  <div className="popup__number success"><input type="tel" value="7" /></div>
                  <div className="popup__number success"><input type="tel" value="9" /></div>
                  <div className="popup__number"><input type="tel" /></div>
                  <div className="popup__number"><input type="tel" /></div>
                </div>
              </div>

              <button className="popup__btn btn btn_blue">Autenticar</button>

            </div>

            <div className="popup__item js-tabs-item">
              <div className="popup__box">
                <div className="popup__title h6">Sessões Ativas</div>
                <table className="popup__table">
                  <tr>
                    <th>DATA/HORA</th>
                    <th>DISPOSITIVO</th>
                    <th>PAÍS</th>
                    <th>IP</th>
                    <th> </th>
                  </tr>
                  <tr>
                    <td>2020-12-02 07:50:18</td>
                    <td>iMac Pro</td>
                    <td><span className="popup__flag">🇺🇸</span> USA </td>
                    <td>123.45.678.987</td>
                    <td>
                      <button className="popup__close">
                        <svg className="icon icon-close">
                          <use xlinkHref="img/sprite.svg#icon-close"></use>
                        </svg>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>

              <div className="popup__box">
                <div className="popup__title h6">Histórico de Login</div>
                <table className="popup__table">
                  <tr>
                    <th>DATA/HORA</th>
                    <th>DISPOSITIVO</th>
                    <th>PAÍS</th>
                    <th>IP</th>
                    <th>2FA</th>
                  </tr>
                  <tr>
                    <td>2020-12-02 07:50:18</td>
                    <td>iMac Pro</td>
                    <td><span className="popup__flag">🇻🇳</span> VN</td>
                    <td>123.45.678.987</td>
                    <td><svg className="icon icon-check">
                        <use xlink:href="img/sprite.svg#icon-check"></use>
                      </svg></td>
                  </tr>
                  <tr>
                    <td>2020-12-02 07:50:18</td>
                    <td>iPhone X</td>
                    <td><span className="popup__flag">🇺🇸</span> USA </td>
                    <td>123.45.678.987</td>
                    <td><svg className="icon icon-check">
                        <use xlink:href="img/sprite.svg#icon-check"></use>
                      </svg></td>
                  </tr>
                  <tr>
                    <td>2020-12-02 07:50:18</td>
                    <td>iMac Pro</td>
                    <td><span className="popup__flag">🇻🇳</span> VN</td>
                    <td>123.45.678.987</td>
                    <td><svg className="icon icon-check">
                        <use xlink:href="img/sprite.svg#icon-check"></use>
                      </svg></td>
                  </tr>
                  <tr>
                    <td>2020-12-02 07:50:18</td>
                    <td>iMac Pro</td>
                    <td><span className="popup__flag">🇺🇸</span> USA </td>
                    <td>123.45.678.987</td>
                    <td><svg className="icon icon-check">
                        <use xlink:href="img/sprite.svg#icon-check"></use>
                      </svg></td>
                  </tr>
                  <tr>
                    <td>2020-12-02 07:50:18</td>
                    <td>iMac Pro</td>
                    <td><span className="popup__flag">🇺🇸</span> USA </td>
                    <td>123.45.678.987</td>
                    <td><svg className="icon icon-check">
                        <use xlink:href="img/sprite.svg#icon-check"></use>
                      </svg></td>
                  </tr>
                </table>
              </div>
            </div> */}
          </div>
        </div>
        <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => onClose()} >x</button>
      </div>
    </div>
  )
}

export default ConfigModal;