import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const blueApiV1 = createApi({
  reducerPath: "blueApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL_API_V1,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token.token) {
        headers.set("Authorization", `${token.type} ${token.token}`);
      }

      headers.set("Access-Control-Allow-Origin", "*");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ username, password }) => {
        return {
          url: `user/auth/token/`,
          method: "POST",
          body: `grant_type=password&username=${username}&password=${password}`,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Access-Control-Allow-Origin": "*"
          }
        }
      },
    }),

    personalData: builder.query({
      query: () => {
        return {
          url: `user/me`,
          method: "GET"
        }
      },
    }),

    myCompanies: builder.query({
      query: () => {
        return {
          url: `insurance/business/company/all`,
          method: "GET",
        }
      },
    }),

    uploadDataBase: builder.mutation({
      query: ({
        event_date,
        cost,
        tuss_id,
        hash,
        gender,
        birth_date,
        company_cnpj,
        company_name,
        dataBase
      }) => {
        return {
          url: `insurance/life/upload?event_date=${event_date}&cost=${cost}&tuss_id=${tuss_id}&hash=${hash}&gender=${gender}&birth_date=${birth_date}&company_cnpj=${company_cnpj}&company_name=${company_name}`,
          method: "POST",
          body: dataBase,
          headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }
      },
    }),

    downloadCompanyLives: builder.mutation({
      query: (id_business) => {
        return {
          url: `insurance/prediction/download_csv/all/company/${id_business}`,
          method: "GET",
        }
      },
    }),
  }),
});

export const { useLoginUserMutation, usePersonalDataQuery, useMyCompaniesQuery, useUploadDataBaseMutation, useDownloadCompanyLivesMutation } = blueApiV1;