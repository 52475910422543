import React from 'react';

import LogoImg from '../../../assets/logo-placeholder.svg';

import {
  Container,
  Header,
  Logo,
  Menu,
  MenuItem,
  Navbar,
} from './styles';

const Sidebar = () => {

  return (
    <Container>
      <Header>
        <Logo src={LogoImg} width={35} />
      </Header>

      <Navbar>
        <Menu>
          <MenuItem>
            <span></span>
            <span></span>
          </MenuItem>
          <MenuItem>
            <span></span>
            <span></span>
          </MenuItem>
          <MenuItem>
            <span></span>
            <span></span>
          </MenuItem>
          <MenuItem>
            <span></span>
            <span></span>
          </MenuItem>
          <MenuItem>
            <span></span>
            <span></span>
          </MenuItem>
          <MenuItem>
            <span></span>
            <span></span>
          </MenuItem>
        </Menu>
      </Navbar>
    </Container>
  );
}

export default Sidebar;
