import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false
};

const configModalSlice = createSlice({
  name: "configModal",
  initialState,
  reducers: {
    toggleConfigModal(state, action) {
      state.open = action.payload;
    },
  },
});

export const { toggleConfigModal } = configModalSlice.actions;
export default configModalSlice.reducer;