import React, { Suspense } from "react";

import Routes from "~/routes";

import { GlobalStyles } from "~/styles";

import { BrowserRouter as Router } from "react-router-dom";

import LoadingPage from "./pages/generic/loading-page";
import { ScrollToTop } from "./utils";

const App = () => {
  return (
    <Router>
      {GlobalStyles}
      <ScrollToTop />
      <Suspense fallback={<LoadingPage />}>
        <Routes />
      </Suspense>
    </Router>
  );
};

export default App;
