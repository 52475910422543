import React, { useEffect, useState } from "react"; 

import { Switch, useLocation } from "react-router-dom";

import { Routes as BrokerRoutes } from "./management/broker";
import { Routes as GenericRoutes } from "./management/generic";
import Layout from "~/components/Layout";

import { toggleTutorial, setCurrentTutorial } from "~/store/slices/tutorial";
import { initUser } from "~/store/slices/user";
import { logOffUser } from "~/store/slices/auth";
import { useSelector, useDispatch } from 'react-redux';
import { useUpdateTutorialStatusMutation, usePersonalDataQuery } from '../services/api/fleme';

import Sidebar from "../components/Sidebar";
import { Tutorial, StepContent } from "../components/Tutorial";

import styled from "@emotion/styled";

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  background: var(--color-background);
  position: relative;
`

const stepDefaultStyles = {
  borderRadius: '9px',
  backgroundColor: '#F4F4F7',
  padding: '35px 25px 12px',
}

const stepSuccessStyles = {
  borderRadius: '9px',
  backgroundColor: '#4F54F6',
  padding: '35px 25px 12px',
}

let goStep;

const steps = [
  {
    selector: '#tour-home-new-base',
    content: ({goTo}) => {
      goStep = goTo
      return(
        <StepContent title="Comece a prever!" text="Para começar, clique em nova base." />
      )
    },
    action: (node) => {
      node.onclick = () => { 
        setTimeout(() => {
          goStep(1)
        }, 500);
      }
    },
    style: stepDefaultStyles
  },
  {
    selector: '#tour-home-new-base-open-sidebar',
    content: () => {
      return(
        <StepContent title="Clique aqui para criar a sua primeira empresa." />
      )
    },
    style: stepDefaultStyles
  },
  {
    selector: '#tour-home-input-data',
    content: <StepContent title="Adicionar dados" text="Escreva o nome e cnpj da nova empresa, e adicione a logo." />,
    action: node => {
      node.focus();
    },
    style: stepDefaultStyles
  },
  {
    content: () => {
      return(
        <StepContent success title="Tudo pronto, você criou uma nova empresa. Agora precisamos adicionar uma nova base." />
      )
    },
    style: stepSuccessStyles
  },
]

const Routes = () => {
  const dispatch = useDispatch();
  const { tutorial, auth } = useSelector(state => state);
  const { pathname } = useLocation();
  const [startTutorial, setStartTutorial] = useState(false);

  // Pegar dados do usuário
  const {
    data: personalData,
    refetch: personalDataFetch,
    error: personalError
  } = usePersonalDataQuery();

  // Atualizar status do tutorial do usuário
  const [updateTutorialStatus] = useUpdateTutorialStatusMutation();

  // Recarregar os dados de usuário
  useEffect(() => {
    personalDataFetch();
  }, [personalDataFetch])

  // Fazer logout se der erro no usuário
  useEffect(() => {
    if(personalError?.status === 401) {
      dispatch(logOffUser());
    }
  }, [personalError, dispatch])

  // Popular os dados de usuário
  useEffect(() => {
    if(personalData) {
      dispatch(initUser({
        name: personalData.name,
        email: personalData.email,
        id: personalData.id_user,
        id_business: personalData.id_business,
        tutorials: personalData.tutorial,
        business_name: personalData.business_name,
        gender: personalData.gender,
        username: personalData.username,
        birth_date: personalData.birth_date
      }))

      let currentTuto = Object.keys(personalData.tutorial).find(item => personalData.tutorial[item] === false);

      dispatch(setCurrentTutorial(currentTuto))
    }
  }, [personalData, dispatch])

  useEffect(() => {
    if(pathname === '/home' && tutorial.currentTutorial === 'createBusiness' && tutorial.currentStep === 0) {
      dispatch(toggleTutorial(true))
    }
  }, [pathname, tutorial, dispatch]);

  useEffect(() => {
    setStartTutorial(tutorial.isOpen);
  }, [tutorial])

  const closeCreateBusinnessTutorial = () => {
    updateTutorialStatus({createBusiness: true});
    dispatch(toggleTutorial(false))
  }

  return (
    <Switch>
      {auth.logged ? (
        <Wrapper>
          <Tutorial
            steps={steps}
            start={startTutorial}
            onRequestClose={() => closeCreateBusinnessTutorial()}
          />
          <Sidebar />
          <Layout>
            <BrokerRoutes />
          </Layout>
        </Wrapper>
      ) : <GenericRoutes />}
    </Switch>
  );
};

export default Routes;
