import styled from '@emotion/styled/macro';
import { css, keyframes } from '@emotion/react/';
import subItemDetail from '~/assets/item-menu.svg';

import { mediaQuery } from "~/styles";

const hideAnimation = keyframes`
  90% {
    opacity: 0;
    transform: scale(0.4);
  }

  100% {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    transform: scale(0.4);
  }
`;

export const Container = styled.aside`
  background-color: #FFF;
  padding: 12px 20px;
  border-right: 1px solid #E4E4E4;
  height: 100vh;
  overflow: hidden;
  transition: 0.2s;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 100%;
  transform: translateX(100%);

  ${props => props.isClosed && css`
    transform: translateX(0);

    ${Logo} {
      animation: ${hideAnimation} 0.2s forwards alternate;
    }

    ${CompanyName} {
      display: none;
    }

    ${ItemSubMenu} {
      padding: 0;
      margin: 0;

      &::before {
        display: none;
      }

      li {
        padding-left: 0;

        &::before {
          display: none;
        }
      }
      
    }
    
    ${ToggleButton} {
      transform: scaleX(-1)
    }

    ${ItemLink}, ${ItemButton}, ${ItemSubLink} {
      padding: 1rem;

      & span {
        opacity: 0;
      }
    }
  `}

  ${mediaQuery('md')} {
    width: ${props => props.isClosed ? '95px' : '255px'};
    min-width: ${props => props.isClosed ? '95px' : '255px'};
    top: 0;
    right: initial;
    left: 0;
    transform: initial;
  }
`

export const Header = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 30px 1rem 0;
  
  ${mediaQuery('sm')} {
    margin-bottom: 2.5rem;
    padding: 50px 1rem 0;
    height: 85px;
  }
`;

export const Logo = styled.img``;

export const ToggleButton = styled.button`
  background: none;
  border: none;
  border-radius: 0;
  transition: 0.2s;
  cursor: pointer;
  transform: scaleX(1);
`;

export const Navbar = styled.nav``;

export const Menu = styled.ul``;

export const MenuItem = styled.li``;

export const ItemIcon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  fill: #808191;
`;

export const ItemLink = styled.div`
  border-radius: 0.75rem;
  cursor: pointer;
  display: block;
  padding: 1rem 1.25rem;
  transition: 0.2s;
  white-space: nowrap;
  line-height: 1;

  &:hover,
  &.active {

    span {
      color: var(--color-primary-light);
    }

    background-color: #FBFBFB;

    svg {
      fill: var(--color-primary-light);
    }
  }
  
  &.active {
    background-color: #F7F7F7;
  } 
`;

export const ItemSubLink = styled(ItemLink)`
  width: 100%;
  color: #808191;
`

export const ItemButton = styled.button`
  border-radius: 0.75rem;
  cursor: pointer;
  display: block;
  padding: 1rem 1.25rem;
  transition: 0.2s;
  white-space: nowrap;
  background: none;
  border: none;

  svg {
    stroke: #808191;
  }

  &:hover span {
    color: var(--color-primary-light);
  }

  &:hover svg {
    fill: var(--color-primary-light);
    stroke: var(--color-primary-light);
  }
`;

export const CompanyName = styled.h3`
  margin: 16px 29px 0;
  position: relative;
  padding-left: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #808191;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 3px);
    left: 0;
    width: 4px;
    height: 4px;
    padding: 1px;
    border-radius: 50%;
    border: 2px solid #808191;
  }
`

export const ItemSubMenu = styled.ul`
  position: relative;
  margin-left: 31px;
  margin-top: 16px;
  margin-bottom: 16px;

  &::before {
    content: '';
    width: 2px;
    height: calc(100% - 35px);
    background-color: #E4E4E4;
    position: absolute;
    top: 0;
    left: 0;
  }

  li {
    position: relative;
    padding-left: 16px;

    &::before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: calc(50% - 11px);
      left: 0;
      background-image: url(${subItemDetail});
      background-size: 100% 100%;
    }
  }
`

export const ItemText = styled.span`
  color: #808191;
  font: 600 0.875rem 'Inter', sans-serif;
  margin-left: 1rem;
  text-decoration: none;
  transition: 0.12s;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
`;
