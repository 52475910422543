import { Global, css } from "@emotion/react";

import "react-placeholder/lib/reactPlaceholder.css";
import 'swiper/swiper.min.css';
import "swiper/modules/pagination/pagination.min.css";
import "./reactTour.css";
import "gridjs/dist/theme/mermaid.css";
import "./gridTable.css";

export const GlobalStyles = (
  <Global
    styles={css`
      :root {
        --color-primary: #1730ff;
        --color-primary-light: #355DFF;
        --color-secondary: #58c23b;
        --color-input: #b5b5b7;
        --color-lightgrey: rgba(228, 228, 228, 0.3);
        --color-body: #11142D;

        --font-default: 'Poppins', sans-serif;
        --font-secondary: 'Inter', sans-serif;
        
        --fw-default: 200;
        --fw-medium: 200;

        --radius: 12px;

        --color-background: #fff;
        --color-sidebar: #ffffff;

        --icon-default: #7f8fb6;
        --icon-selected: #1730ff;

        --black: #323232;

        --transition: cubic-bezier(0.8, 0.04, 0.02, 0.98);
      }

      *,
      *::after,
      *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        backface-visibility: hidden;
        text-rendering: geometricprecision;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
      }

      html,
      body {
        font-size: 16px;
        font-weight: var(--fw-default);
        color: var(--color-body);
        overflow-x: hidden;
      }

      ul {
        list-style: none;
      }

      a {
        text-decoration: none;
      }

      img {
        max-width: 100%;
      }
    `}
  />
);

const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1650,
};

export const mediaQuery = (size = "sm") => {
  // se não existir, vamos mandar o número escolhido
  if (!breakpoints[size]) return `@media (min-width: ${size}px)`;

  // retornamos o size escolhido
  return `@media (min-width: ${breakpoints[size]}px)`;
};
