import styled from "@emotion/styled/macro";
import { keyframes, css } from "@emotion/react";
import { mediaQuery } from "~/styles";

const placeholder = keyframes`
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
`;


export const StyledLayout = styled.div`
  width: 100%;
  
  ${mediaQuery('lg')} {
    transition: width ease .2s;
    width: ${props => props.sidebarIsOpen ? 'calc(100% - 255px)' : '100%'};
  }

  ${props => props.sidebarIsOpen && css`
    ${Content} {
      transform: translateX(255px);
  
      ${mediaQuery('sm')} {
        transform: initial;
      }
    }
  `}
`;

export const Content = styled.main`
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  transition: all ease .2s;
  position: relative;
  background-color: #FFF;
  transform: translateX(0);
  overflow-x: hidden;
  
  ${mediaQuery('sm')} {
    padding: 0 64px 64px;
  }
  
  ${mediaQuery('md')} {
    padding: 0 16px 64px;
  }
  
  ${mediaQuery('lg')} {
    padding: 0 64px 64px;
  }
`;

export const MainContent = styled.div`
  margin-top: 129px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 84px;

  ${mediaQuery('sm')} {
    padding: 0;
  }

  ${mediaQuery('md')} {
    padding-left: 95px;
  }

  ${mediaQuery('lg')} {
    padding-left: 0;
  }

  span {
    height: 104px;
    background-color: #F4F4F7;
    border-radius: 24px;
    max-width: 100%;
    width: 307px;
    animation: ${placeholder} 1.5s infinite;
    
    &:nth-of-type(2) {
      width: 1003px;
    }
    
    &:nth-of-type(4) {
      width: 707px;
    }
    
    &:nth-of-type(5) {
      width: 55px;
    }
    
    &:nth-of-type(6) {
      width: 385px;
    }
  }
`
