import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  currentStep: 0,
  currentTutorial: '',
};

const tutorialSlice = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    toggleTutorial(state, action) {
      state.isOpen = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setCurrentTutorial(state, action) {
      state.currentTutorial = action.payload;
    }
  },
});

export const { toggleTutorial, setCurrentStep, setCurrentTutorial } = tutorialSlice.actions;
export default tutorialSlice.reducer;