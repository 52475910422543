import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { mediaQuery } from "~/styles";

export const StyledLayout = styled.div`
  width: 100%;
  
  ${mediaQuery('lg')} {
    transition: all ease .2s;
    width: ${props => props.sidebarIsOpen ? 'calc(100% - 255px)' : 'calc(100% - 95px)'};
    margin-left: ${props => props.sidebarIsOpen ? '255px' : '95px'};
  }

  ${props => props.sidebarIsOpen && css`
    ${Content} {
      transform: translateX(255px);
  
      ${mediaQuery('sm')} {
        transform: initial;
      }
    }
  `}
`;

export const Content = styled.main`
  width: 100%;
  margin: 0 auto;
  transition: all ease .2s;
  position: relative;
  background-color: #FEFEFE;
  transform: translateX(0);
  
  ${mediaQuery('sm')} {
    padding: 0 64px 64px;
  }
  
  ${mediaQuery('md')} {
    padding: 0 16px 64px;
  }
  
  ${mediaQuery('lg')} {
    padding: 0 64px 64px;
  }
`;

export const MainContent = styled.div`
  padding: 1rem;

  ${mediaQuery('sm')} {
    padding: 0;
  }

  ${mediaQuery('md')} {
    padding-left: 95px;
  }

  ${mediaQuery('lg')} {
    padding-left: 0;
  }
`
