import React from "react";
import Sidebar from "./Sidebar";
import MainContentPlaceholder from "../MainContentPlaceholder";

const LoadingLayout = () => {
  return (
    <>
      <Sidebar />
      <MainContentPlaceholder />
    </>
  );
};

export default LoadingLayout;
