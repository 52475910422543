import React, { useEffect, useState } from 'react';

import { useLocation } from "react-router-dom";

import avatarImg from '~/assets/user-icon.png'
import svgBase from '../../assets/sprite.svg';

import { useSelector, useDispatch } from 'react-redux';
import { logOffUser } from "../../store/slices/auth";
import { toggleConfigModal } from '../../store/slices/configModal';
import { toggleSidebar } from "~/store/slices/sidebar";

import OriginalNotifications from '../OriginalNotifications';

import * as S from './styles';

const OriginalHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isSidebarClosed = useSelector(state => state.sidebar.closed);

  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);

  useEffect(() => {
    if(showNotifications) {
      setShowNotifications(false)
    }
  }, [location])
  
  const loggout = () => {
    dispatch(logOffUser());
  }
  
  return(
    <S.Header className="header">      
      <div className="header__group">
        {/* {companyInfo.name && idMock?.periodData >= 24 && (
          <div className="header__indicator">
            <div className="header__img">
              <img src={periodTag} alt="" />
            </div>
            { periodText }
          </div>
        )} */}

        <S.NotificationBadge active={false}>
          <button className="notificationBadge__button active" onClick={() => setShowNotifications(!showNotifications)}>
            <svg>
              <use xlinkHref={`${svgBase}#icon-notification`} />
            </svg>
          </button>
          <OriginalNotifications active={showNotifications} onClickOutside={() => setShowNotifications(false)} />
        </S.NotificationBadge>
      </div>

      <S.Avatar active={showUserModal}>
        <img src={avatarImg} alt="avatar" onClick={() => setShowUserModal(!showUserModal)} />

        <S.UserOverlay onClick={() => setShowUserModal(false)} />

        <S.HeaderDropdown>
          <ul>
            <S.DropdownOption onClick={() => dispatch(toggleConfigModal(true))}>
              <svg width={22} height={22}>
                <use xlinkHref={`${svgBase}#icon-settings`} />
              </svg>
              Configurações
            </S.DropdownOption>
            <S.DropdownOption onClick={() => loggout()}>
              <svg width={22} height={16}>
                <use xlinkHref={`${svgBase}#icon-loggout`} />
              </svg>
              Sair
            </S.DropdownOption>
          </ul>
        </S.HeaderDropdown>
      </S.Avatar>
      
      <button className="header__toggle" style={{ marginLeft: 10 }} onClick={() => dispatch(toggleSidebar(!isSidebarClosed))}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24">
          <path d="M22 12H3" stroke="#11142d"></path>
          <g stroke="#808191">
            <path d="M22 4H13"></path>
            <path opacity=".301" d="M22 20H13"></path>
          </g>
          <path d="M7 7l-5 5 5 5" stroke="#11142d"></path>
        </svg>
      </button>
    </S.Header>
  )
}

export default OriginalHeader;