import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const flemingApi = createApi({
  reducerPath: "flemingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_URL_API_FLEMING,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token.token) {
        headers.set("x-authorization", `${token.type} ${token.token}`);
      }

      headers.set("Access-Control-Allow-Origin", "*");

      return headers;
    },
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ username, password }) => {
        return {
          url: `/authentication/login`,
          method: "POST",
          body: {"user": username, "password": password},
          headers: {
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*"
          }
        }
      },
    }),
    personalData: builder.query({
      query: () => {
        return {
          url: `user/me`,
          method: "GET"
        }
      },
    }),
    uploadDataBase: builder.mutation({
      query: (data) => {
        let formData = new FormData();

        for (var key in data ) {
          formData.append(key, data[key]);
        }

        return {
          url: `/prediction/base/upload`,
          method: "POST",
          body: formData,
          headers: {
            "Accept": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
          }
        }
      },
    }),
    createBusiness: builder.mutation({
      query: (data) => {
        let formData = new FormData();

        for (var key in data ) {
          formData.append(key, data[key]);
        }

        return {
          url: `/company`,
          method: "POST",
          body: formData,
          headers: {
            "Accept": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
          }
        }
      },
    }),
    myCompanies: builder.query({
      query: () => {
        return {
          url: `company`,
          method: "GET",
        }
      },
    }),
    myDataBases: builder.query({
      query: () => {
        return {
          url: `prediction/base`,
          method: "GET",
        }
      },
    }),
    updateTutorialStatus: builder.mutation({
      query: (tutorialName) => {
        return {
          url: `/user/me`,
          method: 'PATCH',
          body: {
            tutorial: {
              ...tutorialName
            }
          }
        }
      }
    }),
    generatePredictionFile: builder.mutation({
      query: (id) => {
        return {
          url: `company/${id}/generate-prediction-file`,
          method: "GET"
        }
      }
    }),
    getPredictionFile: builder.mutation({
      query: (id) => {
        return {
          url: `company/${id}/prediction-file`,
          method: "GET"
        }
      }
    }),

    companyLives: builder.query({
      query: (params) => {
        const { id_business } = params;
  
        let queryParams = '';
  
        Object.entries(params).map(item => {
          if(item[0] !== 'id_business') {
            return queryParams += item[1] !== undefined ? `&${item[0]}=${item[1]}` : '';
          }
          return false;
        })
  
        return {
          url: `company/${id_business}/lives?page_size=500${queryParams}`,
          method: "GET",
        }
      },
    }),
  
    companySpecificLife: builder.query({
      query: ({ id_business, id_life }) => {
        return {
          url: `company/${id_business}/lives/${id_life}`,
          method: "GET",
        }
      },
    }),
  
    companyLifeEvents: builder.query({
      query: ({ id_business, id_life }) => {
        return {
          url: `company/${id_business}/lives/${id_life}/events`,
          method: "GET",
        }
      },
    }),
  


  }),
});

export const {
  useLoginUserMutation,
  usePersonalDataQuery,
  useUploadDataBaseMutation,
  useCreateBusinessMutation,
  useMyCompaniesQuery,
  useMyDataBasesQuery,
  useUpdateTutorialStatusMutation,
  useGeneratePredictionFileMutation,
  useGetPredictionFileMutation,
  useCompanyLivesQuery,
  useCompanySpecificLifeQuery,
  useCompanyLifeEventsQuery
} = flemingApi;