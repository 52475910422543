import styled from "@emotion/styled";

export const Title = styled.p`
  font-family: var(--font-default);
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 15px;
  color: ${props => props.success ? '#FFFFFF' : '#11142D'};
`;

export const Text = styled.p`
  font-family: var(--font-secondary);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: ${props => props.success ? '#FFFFFF' : '#808191'};
`;