import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: undefined,
  wallet_value: undefined,
  id_company: undefined
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    associateCompany(state, action) {
      state.name = action.payload.name;
      state.wallet_value = action.payload.wallet_value;
      state.id_company = action.payload.id_business;
    },
    resetCompany(state) {
      state.name = undefined;
      state.wallet_value = undefined;
      state.id_company = undefined;
    }
  },
});

export const { associateCompany, resetCompany } = companySlice.actions;
export default companySlice.reducer;