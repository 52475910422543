import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(17, 20, 45, 0.1);
  display: none;
  z-index: 700;
`;

export const Notifications = styled.div`
  position: absolute;
  background: #ffffff;
  border-radius: 20px;
  padding: 0 24px 32px;
  box-shadow: 0 15px 30px rgba(17, 20, 45, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
  top: calc(100% + 33px);
  right: -50px;
  width: 375px;
  z-index: 999;

  .header__notification {

    &:visited {
      color: inherit;
    }

    &:hover {

      .header__info {
        color: #355dff;
      }
    }
  }
`;

export const Wrapper = styled.div`
  ${props => props.active && css`

    ${Notifications} {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    ${Overlay} {
      display: block;
    }
  `}
`

export const Icon = styled.div`

  ${props => props.type === 'blue' && css`
    background: linear-gradient(180deg, #355DFF 0%, #3B55F6 57.81%, #3F50EF 100%);
  `}

  ${props => props.type === 'loading' && css`
    background: linear-gradient(180deg, #A596EB 0%, #6C5DD3 100%);
  `}

  ${props => props.type === 'update' && css`
    background: linear-gradient(180deg, #FFCE6A 0%, #FF9F38 100%);
  `}

  ${props => props.type === 'finished' && css`
    background: linear-gradient(180deg, #86E1A0 0%, #4FBF67 100%);
  `}
`;