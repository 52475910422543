import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   id_business: '',
   list: [],
};

const lifesSlice = createSlice({
  name: "lifes",
  initialState,
  reducers: {
    associateLifes(state, action) {
      state.list = action.payload.list;
      state.id_business = action.payload.id_business;
    },
    resetLifes(state) {
      state.id_business = '';
      state.list = [];
    }
  },
});

export const { associateLifes, resetLifes } = lifesSlice.actions;
export default lifesSlice.reducer;