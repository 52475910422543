import { lazy } from "react";

const Home = lazy(() => import("./home"));
const Settings = lazy(() => import("./settings"));
const IndividualBusiness = lazy(() => import("./individual-business"));
const BusinessAggregates = lazy(() => import("./business-aggregates"));
const IndividualAggregate = lazy(() => import("./individual-aggregate"));
const OverviewBase = lazy(() => import("./overview-base"));
const About = lazy(() => import("./about"));
const UploadBase = lazy(() => import("./upload-database"));
const Notifications = lazy(() => import("./notifications"));

export {
  Home,
  Settings,
  IndividualBusiness,
  BusinessAggregates,
  IndividualAggregate,
  OverviewBase,
  About,
  UploadBase,
  Notifications
};
