import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { mediaQuery } from "~/styles";

export const Header = styled.header`
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 24px 1rem;

  ${mediaQuery('sm')} {
    padding: 32px 0;
  }

  ${mediaQuery('md')} {
    padding: 36px 0;
  }

  ${mediaQuery('lg')} {
    padding: 48px 0 36px;
  }

  .header__indicator {
    font-size: 14px;
  }
`;

export const NotificationBadge = styled.div`
  margin-right: 0;
  font-size: 0;
  position: relative;
  // &:hover {
  //   border-radius: 50%;
  //   background-color: #F7F7F7;
  // }

  .notificationBadge__button {
    width: 24px;
    height: 24px;
    font-size: 0;
    position: relative;

    &::before{
      content: "";
      position: absolute;
      top: -4px;
      right: -4px;
      width: 8px;
      height: 8px;
      background: #FF6628;
      border-radius: 50%;
      opacity: ${props => props.active ? '1' : '0'};
    }

    // &:hover {
    //   // background: var(--color-primary);
    //   border-radius: 50%;
    //   box-shadow: 0 0 0 2px #355dff;
    //   // color: #355DFF;
  
    // }

    svg {
      width: 0.91em;
      height: 1em;
      fill: #808191;
      font-size: 20px;
    }

    &:hover {
      svg {
        fill: var(--color-primary-light);
      }
      // background-color: #F7F7F7;
  
    }
  }
`;

export const UserOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(17, 20, 45, 0.1);
  display: none;
  z-index: 700;
`;

export const HeaderDropdown = styled.div`
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  background: #ffffff;
  border-radius: 15px;
  padding: 24px;
  box-shadow: 0 15px 30px rgba(17, 20, 45, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: all .2s;
  z-index: 999;
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  margin-left: 36px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }

  &:hover {
    // background: var(--color-primary);
    // background: var(--color-primary);
    box-shadow: 0 0 0 2px #C8C7DD;
    // color: #355DFF;

  }

  ${props => props.active && css`

    ${HeaderDropdown} {
      visibility: visible;
      opacity: 1;
    }

    ${UserOverlay} {
      display: block;
    }
  `}
`;

export const DropdownOption = styled.li`
  color: #808191;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: var(--font-secondary);

  &:last-of-type {
    margin: 0;
  
    svg {
      stroke: transparent;
    }
  }

  svg {
    margin-right: 4px;
    fill: #808191;
    stroke: #808191;
  }
  
  &:hover {
    color: var(--color-primary-light);

    svg {
      margin-right: 4px;
      fill: var(--color-primary-light);
      stroke: var(--color-primary-light);
    }
  }
`;