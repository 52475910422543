import React from 'react';

import { Link } from 'react-router-dom';
import fileIcon from '../../assets/paper.png';

import * as S from './styles';

import blueLogo from '../../assets/blue-icon.svg';


const mockData = [
  // {
  //   type: 'loading', // update | blue | finished | loading
  //   title: 'Grand Hyatt Rio de Janeiro', // String
  //   message: 'Carregando base', // String
  //   updateDate: '2 dias de 7', // Date object
  //   link: './notifications'
  // },
  // {
  //   type: 'finished', // update | blue | finished | loading
  //   title: 'Grand Hyatt São Paulo', // String
  //   message: 'Base atualizada', // String
  //   updateDate: 'Há 24m', // Date object
  //   link: './notifications'
  // },
  {
    type: 'blue', // update | blue | finished | loading
    title: 'Seja bem-vindo(a) à Blue e ao futuro da saúde 🎉', // String
    updateDate: 'Há 13 dias', // Date object
    link: './notifications'
  },
  // {
  //   type: 'update', // update | blue | finished | loading
  //   title: 'Grand Hyatt Belo Horizonte', // String
  //   updateDate: 'Há 5 dias', // Date object
  //   link: './notifications'
  // },
];

// const NotificationCard = ({type, title, message, updateDate, link, onItemClick}) => {
//   const [tagText, setTagText] = useState('green');
//   useEffect(() => {
//     switch(type) {
//       case 'update':
//         setTagText('Atualizar')
//         break;
//       case 'blue':
//         setTagText('')
//         break;
//       case 'finished':
//         setTagText('Atualizada')
//         break;
//       case 'loading':
//         setTagText('Carregando')
//         break;
//       default:
//         return false
//     }
//   }, [type])

//   return(
//     <S.Item onClick={() => onItemClick()} to={link} type={type}>
//       <S.Icon>
//         <img src={type === 'blue' ? blueLogo : fileIcon} alt='icone'/>
//       </S.Icon>
//       <div style={{ width: '100%' }}>
//         {message ? (
//           <S.Title>{message}: <span>{title}</span></S.Title>
//         ) : (    
//           <S.Title><span>{title}</span></S.Title>
//         )}
        
//         <S.Details>
//           <p>{updateDate}</p>
//           {type === 'loading' ? (
//             <S.Loading percent={75}/>
//           ) : (
//             tagText && <S.Tag>{tagText}</S.Tag>
//           )}

//         </S.Details>
//       </div>
//     </S.Item>
//   );
// }


const OriginalNotifications = ({active, onClickOutside}) => {
  return(
    <S.Wrapper active={active}>
      <S.Overlay onClick={() => onClickOutside()} />
      <S.Notifications>
        <div className="header__notifications">
          {
            mockData.map((item, index) => {
              return(
                <Link key={index} to="/notifications" className="header__notification">
                  <S.Icon className="header__icon" type={item.type}>
                    <img src={item.type === 'blue' ? blueLogo : fileIcon} alt='icone'/>
                  </S.Icon>
                  <div className="header__details">
                    <div className="header__info">{item.title}</div>
                    <div className="header__line">
                      <div className="header__time">{new Date().toLocaleDateString('pt-Br')}</div>
                      {/* <div className="header__status"></div> */}
                    </div>
                  </div>
                </Link>
              );
            })
          }
          {/* <Link to="/notifications" className="header__notification">
            <div className="header__icon">
              <img src={blueLogo} alt="" />
            </div>
            <div className="header__details">
              <div className="header__info">Seja bem-vindo à Blue e ao futuro da saúde 🎉</div>
              <div className="header__line">
                <div className="header__time">10/08</div>
                <div className="header__status"></div>
              </div>
            </div>
          </Link> */}
        </div>
        <Link className="header__btn btn btn_blue btn_wide" to="/notifications">Ver notificações</Link>
      </S.Notifications>
    </S.Wrapper>
  );
}

export default OriginalNotifications;