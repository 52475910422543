import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hash: '',
  procedure_list: [], //probability, tuss_subgroup, cost
  id_gender: '',
  birth_date: ''
};

const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {
    associateLive(state, action) {
      state.hash = action.payload.hash;
      state.procedure_list = action.payload.procedure_list;
      state.id_hash = action.payload.id_hash;
      state.birth_date = action.payload.birth_date;
    },
    resetLive(state) {
      state.hash = '';
      state.procedure_list = [];
      state.id_hash = '';
      state.birth_date = '';
    }
  },
});

export const { associateLive, resetLive } = liveSlice.actions;
export default liveSlice.reducer;