import React, { useEffect } from "react";
import { Route, useLocation, Redirect } from "react-router-dom";

import {
  Home,
  Settings,
  IndividualBusiness,
  BusinessAggregates,
  IndividualAggregate,
  OverviewBase,
  About,
  UploadBase,
  Notifications
} from "~/pages/broker";

export const Routes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector('main').scrollTo(0, 0);
  }, [pathname])

  return (
    <>
      <Route exact path="/home" component={Home} isPrivate />
      <Route exact path="/settings" component={Settings} isPrivate />
      <Route exact path="/business/:id" component={IndividualBusiness} isPrivate />
      <Route exact path="/business/:id/lives" component={BusinessAggregates} isPrivate />
      <Route exact path="/business/:id/lives/:liveId" component={IndividualAggregate} isPrivate />
      <Route exact path="/overview" component={OverviewBase} isPrivate />
      <Route exact path="/about-us" component={About} />
      <Route exact path="/upload-database" component={UploadBase} />
      <Route exact path="/notifications" component={Notifications} />
      <Route exact path="/">
        <Redirect to='/home' />
      </Route>
      <Route exact path="/login">
        <Redirect to='/home' />
      </Route>
    </>
  );
}
