import { configureStore, combineReducers } from "@reduxjs/toolkit";

import storage from 'redux-persist/lib/storage';

import { persistReducer, persistStore } from 'redux-persist';

import { blueApiV1 } from "~/services/api/v1";
import { flemingApi } from "~/services/api/fleme";
import userReducer from "./slices/user";
import authReducer from "./slices/auth";
import businessReducer from "./slices/business";
import companyReducer from "./slices/company";
import lifesReducer from "./slices/lifes";
import liveReducer from "./slices/live";
import sidebarReducer from "./slices/sidebar";
import configModalReducer from "./slices/configModal";
import tutorialReducer from "./slices/tutorial";

const rootReducer = combineReducers({
  [blueApiV1.reducerPath]: blueApiV1.reducer,
  [flemingApi.reducerPath]: flemingApi.reducer,
  user: userReducer,
  auth: authReducer,
  business: businessReducer,
  company: companyReducer,
  lifes: lifesReducer,
  live: liveReducer,
  sidebar: sidebarReducer,
  configModal: configModalReducer,
  tutorial: tutorialReducer
})

const persistConfig = {
  key: 'BLUE_AI',
  storage,
  whitelist: [
    'auth',
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(flemingApi.middleware, blueApiV1.middleware),
});

export const persistor = persistStore(store)