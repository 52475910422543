import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// Components
import ConfigModal from "~/components/ConfigModal";
// import Header from "~/components/Header";
import OriginalHeader from "~/components/OriginalHeader";
// import NewBaseModal from '~/components/NewBaseModal';

// Styles
import { StyledLayout, Content, MainContent } from "./styles";
import { useMediaQuery } from 'react-responsive';

// Hooks
import { useDispatch, useSelector } from 'react-redux';

// State
import { toggleSidebar } from "~/store/slices/sidebar";
import { toggleConfigModal } from "~/store/slices/configModal";

const Layout = (props) => {
  const dispatch = useDispatch();

  const sidebarIsClosed = useSelector(state => state.sidebar.closed);
  const configModalIsOpen = useSelector(state => state.configModal.open);
  // const uploadBaseModalIsOpen = useSelector(state => state.uploadBaseModal.open)

  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' })

  const { pathname } = useLocation();

  useEffect(() => {
    if(!isDesktop) {
      dispatch(toggleSidebar(true))
    }
  }, [pathname, isDesktop, dispatch])

  return (
    <StyledLayout sidebarIsOpen={!sidebarIsClosed}>
      <Content id="main">
        <OriginalHeader />
        <MainContent>
          {props.children}
        </MainContent>
      </Content>
      <ConfigModal onClose={() => dispatch(toggleConfigModal(false))} modalIsOpen={configModalIsOpen} />
    </StyledLayout>
  );
};

export default Layout;
