import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logged: false,
  token: {
    type: "",
    token: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logInUser(state, action) {
      state.logged = true;
      state.token = action.payload.token;
    },
    logOffUser(state) {
      state.logged = false;
      state.token = {};
    },
  },
});

export const { logInUser, logOffUser } = authSlice.actions;
export default authSlice.reducer;