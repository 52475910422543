import React from 'react';
import Tour from 'reactour';

import { useDispatch, useSelector } from 'react-redux';
import { setCurrentStep } from "~/store/slices/tutorial";

import { Title, Text } from './styles';

import tutorialIcon from '../../assets/tutorial-last-step.svg';

const StepContent = ({
  title,
  text,
  success
}) => {
  return(
    <div>
      {title && <Title success={success} dangerouslySetInnerHTML={ {__html: title} } />}
      {text && <Text success={success} dangerouslySetInnerHTML={ {__html: text} } />}
    </div>
  );
}

const Tutorial = ({
  steps,
  start,
  onRequestClose
}) => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.tutorial);

  return(
    <Tour
      disableDotsNavigation
      accentColor="#404EED"
      disableFocusLock
      getCurrentStep={(curr) => dispatch(setCurrentStep(curr + 1))}
      goToStep={currentStep - 1}
      isOpen={start}
      onRequestClose={onRequestClose}
      showButtons={false}
      showCloseButton={false}
      showNavigationNumber={false}
      steps={steps}
      showNavigation={true}
      badgeContent={(curr, tot) => {
        return(
          <>
            {curr === tot ? <img width={14} src={tutorialIcon} alt="tutorial finalizado icone" /> : curr}
          </>
        )
      }}
    />
  );
}

export { Tutorial, StepContent };