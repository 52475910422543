import React from "react";
import { StyledLayout, Content, MainContent } from "./styles";

const MainContentPlaceholder = () => {
  return (
    <>
      <StyledLayout>
        <Content>
          <MainContent>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </MainContent>
        </Content>
      </StyledLayout>
    </>
  );
};

export default MainContentPlaceholder;
