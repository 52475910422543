import React from 'react';

import LogoImg from '../../assets/logo.svg';
import ToggleImg from '../../assets/toggle.svg';
import svgBase from '../../assets/sprite.svg';

// Hooks
import { useDispatch, useSelector } from 'react-redux';

// State
import { toggleSidebar } from "~/store/slices/sidebar";
import { toggleConfigModal } from '~/store/slices/configModal';

// Router
import { useHistory, useLocation } from 'react-router-dom';

import * as S from './styles';

const navigation = [
  {
    label: 'Previsões',
    url: '/home',
    icon: 'icon-blue',
    active: true,
    submenu: [
      { label: 'Despesas' },
      { label: 'Beneficiários' }
    ]
  },
  {
    label: 'Nova base',
    url: '/upload-database',
    icon: 'icon-plus-square',
    active: true,
    id: 'tour-home-new-base',
  },
  {
    label: 'Modelos',
    url: '/about-us',
    icon: 'icon-models',
    active: true,
  },
  {
    label: 'Notificações',
    url: '/notifications',
    icon: 'icon-notification',
    active: true,
  },
]

const Link = ({ label, url, icon, subMenu, id }) => {
  const companyInfo = useSelector((state) => state.company);
  const history = useHistory();
  const { pathname } = useLocation();

  const navigate = (to) => {
    history.push(to);
  }

  return(
    <>
      {subMenu && companyInfo.name ? (
        <>   
          <S.ItemLink onClick={() => navigate(url)}>
            <S.ItemIcon width={22} height={22} >
              <use xlinkHref={`${svgBase}#${icon}`} />
            </S.ItemIcon>
            <S.ItemText>{label}</S.ItemText>
          </S.ItemLink>

          <S.CompanyName>{companyInfo.name}</S.CompanyName>

          <S.ItemSubMenu>
            <li>
              <S.ItemSubLink
                className={`/business/${companyInfo.id_company}/` === pathname && 'active'}
                onClick={() => navigate(`/business/${companyInfo.id_company}/`)}
              >
                <S.ItemIcon width={22} height={22}>
                  <use xlinkHref={`${svgBase}#icon-activity`} />
                </S.ItemIcon>
                <S.ItemText>
                  {subMenu[0].label}
                </S.ItemText>
              </S.ItemSubLink>
            </li>
            <li>
              <S.ItemSubLink
                className={`/business/${companyInfo.id_company}/lives/` === pathname && 'active'}
                onClick={() => navigate(`/business/${companyInfo.id_company}/lives/`)}
              >
                <S.ItemIcon width={22} height={22}>
                  <use xlinkHref={`${svgBase}#icon-people`} />
                </S.ItemIcon>
                <S.ItemText>
                  {subMenu[1].label}
                </S.ItemText>
              </S.ItemSubLink>
            </li>
          </S.ItemSubMenu>
        </>
      ) : (
        <div id={id}>
          <S.ItemLink className={url === pathname && 'active'} onClick={() => navigate(url)}>
            <S.ItemIcon width={22} height={22}>
              <use xlinkHref={`${svgBase}#${icon}`} />
            </S.ItemIcon>
            <S.ItemText>{label}</S.ItemText>
          </S.ItemLink>
        </div>

      )}
    </>
  );
}

const Sidebar = ({onClick}) => {
  const isClosed = useSelector(state => state.sidebar.closed);

  const dispatch = useDispatch();

  return (
    <S.Container isClosed={isClosed} onClick={onClick}>
      <S.Header>
        <S.Logo isClosed={isClosed} src={LogoImg} width={35} />
        <S.ToggleButton onClick={() => dispatch(toggleSidebar(!isClosed))} >
          <img src={ToggleImg} width={24} height={24} alt="abrir ou fechar sidebar" />
        </S.ToggleButton>
      </S.Header>

      <S.Navbar>
        <S.Menu>
          
          {navigation.map((item, index) => {
            return (
              item.active && (
                <S.MenuItem key={index}>
                  <Link
                    label={item.label}
                    url={item.url}
                    icon={item.icon}
                    subMenu={item.submenu}
                    id={item.id ? item.id : undefined}
                  />
                </S.MenuItem>
              )
            )
          })}
          
          <S.MenuItem>
            <S.ItemButton onClick={() => dispatch(toggleConfigModal(true))}>
              <S.ItemIcon width={22} height={22} >
                <use xlinkHref={`${svgBase}#icon-settings`} />
              </S.ItemIcon>
              <S.ItemText>Configurações</S.ItemText>
            </S.ItemButton>
          </S.MenuItem>
        </S.Menu>
      </S.Navbar>
    </S.Container>
  );
}

export default Sidebar;
