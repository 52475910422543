import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: '',
  name: '',
  username: '',
  birth_date: '',
  email: '',
  id_business: '',
  business_name: '',
  tutorials: {},
  gender: '',
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initUser(state, action) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.id_business = action.payload.id_business;
      state.tutorials = action.payload.tutorials;
      state.business_name = action.payload.business_name;
      state.gender = action.payload.gender;
      state.username = action.payload.username;
      state.birth_date = action.payload.birth_date;
    },
    resetUser(state) {
      state = initialState;
    },
  },
});

export const { initUser, resetUser } = userSlice.actions;
export default userSlice.reducer;